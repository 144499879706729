@charset "utf-8";

/* Webfont Setting :
  1. font-family: 'Roboto', sans-serif;
  2. font-family: 'Nanum Gothic', sans-serif;
  3. font-family: 'Open Sans', sans-serif;
  4. font-family: 'Lato', sans-serif;
  5. font-family: 'Noto Sans KR', sans-serif;
  6. font-family: 'Teko', sans-serif;
  7. font-family: 'Teko', sans-serif;
*/
@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700|Nanum+Gothic:400,700|Noto+Sans+KR:100,300,400,500,700|Open+Sans|Teko:wght@300;400;500;600;700&|Roboto:100,300,400,500,700&display=swap');


@font-face {
  font-family: "Apple SD Gothic Neo";
  font-weight: 900;
  src: url("./fonts/AppleSDGothicNeoB.woff") format("woff");
}
@font-face {
  font-family: "Apple SD Gothic Neo";
  font-weight: 800;
  src: url("./fonts/AppleSDGothicNeoEB.woff") format("woff");
}
@font-face {
  font-family: "Apple SD Gothic Neo";
  font-weight: 700;
  src: url("./fonts/AppleSDGothicNeoH.woff") format("woff");
}
@font-face {
  font-family: "Apple SD Gothic Neo";
  font-weight: 600;
  src: url("./fonts/AppleSDGothicNeoL.woff") format("woff");
}
@font-face {
  font-family: "Apple SD Gothic Neo";
  font-weight: 500;
  src: url("./fonts/AppleSDGothicNeoM.woff") format("woff");
}
@font-face {
  font-family: "Apple SD Gothic Neo";
  font-weight: 400;
  src: url("./fonts/AppleSDGothicNeoR.woff") format("woff");
}
@font-face {
  font-family: "Apple SD Gothic Neo";
  font-weight: 300;
  src: url("./fonts/AppleSDGothicNeoSB.woff") format("woff");
}
@font-face {
  font-family: "Apple SD Gothic Neo";
  font-weight: 200;
  src: url("./fonts/AppleSDGothicNeoT.woff") format("woff");
}
@font-face {
  font-family: "Apple SD Gothic Neo";
  font-weight: 100;
  src: url("./fonts/AppleSDGothicNeoUL.woff") format("woff");
}

@font-face {
  /* 사용할 폰트의 이름 */
  font-family: "Apple SD Gothic Neo";
  font-display: fallback;

  /* 
  src: 사용할 폰트의 위치 및 형식 
     Safari, Android, iOS => ttf
     Modern Browsers => woff
     IE6-IE8 => eot
  */
  src: 
    url("./fonts/AppleSDGothicNeoB.woff") format("woff"), 
    url("./fonts/AppleSDGothicNeoEB.woff")format("woff"),
    url("./fonts/AppleSDGothicNeoH.woff") format("woff"),
    url("./fonts/AppleSDGothicNeoL.woff") format("woff"),
    url("./fonts/AppleSDGothicNeoM.woff") format("woff"),
    url("./fonts/AppleSDGothicNeoR.woff") format("woff"),
    url("./fonts/AppleSDGothicNeoSB.woff") format("woff"),
    url("./fonts/AppleSDGothicNeoT.woff") format("woff"),
    url("./fonts/AppleSDGothicNeoUL.woff") format("woff");

  /* 사용할 폰트의 기본 스타일 */
  font-weight: 100;
  font-style: normal;
}


svg:hover {
  opacity: 1;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

body {
  font-family: 300 'Noto Sans KR', sans-serif;
  font-size: 1.4rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

p {
  line-height: 1.5;
}

ul,
ol {
  list-style: none;
}

dl,
dd,
dt {
  margin: 0;
  padding: 0;
}

a {
  color: #000;
  text-decoration: none;
}

table,
tr,
th,
td {
  /* border: 1px solid #000; */
  border-collapse: collapse;
}

caption {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 1.2rem;
}

fieldset {
  border: 0;
}

legend {
  display: block;
  width: 1px;
  height: 1px;
  font-size: 1px;
  text-indent: -9999px;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

input,
label {
  vertical-align: middle;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}

button,
input {
  outline: 0;
}

iframe {
  pointer-events: none;
}


/* Help Class Setting */
/* ir(image replace) - 이미지 대체(치환)기법 */
.ir {
  display: block;
  width: 100%;
  height: 100%;
  text-indent: -9999px;
  overflow: hidden;
  background: no-repeat 0 0;
}

/* 플롯해제 클래스 */
.clearfix::after {
  content: '';
  display: block;
  clear: both;
}

/* 화면 감추기 클래스 */
.screen-hidden {
  display: block;
  width: 1px;
  height: 1px;
  font-size: 1px;
  text-indent: -9999px;
  overflow: hidden;
  margin: 0;
  padding: 0;
}