/***************** 공동영역 선언 *****************/
body {
  max-width: 1920px;
  margin: 0 auto;
}
.app {
  overflow-x: hidden;
  width: 100%;
  background-color: #fff;
  position: inherit !important;
  top: inherit !important;
  font-family: 'Apple SD Gothic Neo', sans-serif;
  z-index: 0;
}
h2 {
  font-size: 4rem;
  font-weight: 100;
  letter-spacing: 1.4px;
  z-index: 1;
}
h3 {
  font-size: 3rem;
  font-weight: 100;
  z-index: 1;
}
h4 {
  font-size: 1.8rem;
  text-align: left;
  font-weight: 300;
  z-index: 1;
}
p {
  font-size: 1.5rem;
  font-weight: 400;
  z-index: 1;
}
img,iframe{
  z-index: 1;
}
/* 헤더 */
.header {
  width: 100%;
  max-width: 1920px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  padding: 2% 7%;
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 1px solid #dfe0eb;
}
.header.header1 {
  border-bottom: inherit;
}
.header .logo img {
  width: 80%;
}
.header .nav {
  display: flex;
  min-width: 40%;
  justify-content: space-between;
}
.header .nav button{
  font-weight: 700;
}
.header.header2 .nav button {
  color: #000;
}
.header.header1 .nav button {
  color: #fff;
}

.header .nav > li {
  margin: 0 5px;
}
.header .nav button {
  width:200px;
  margin-left: 10px;
}
.header .nav button:last-child img {
  display: block;
  width: 20px;
  margin-right: 10px;
}
.header .nav button > ul{
  width:200px;
  position: absolute;
  z-index: 200;

}
.header .nav button > ul > li{
  display: none;
  height: 50px;
  font-size:12px;
  line-height: 50px;
  background-color: #0A1424;
  color: #fff;
  font-weight: 500;
}
.header .nav button > ul > li:first-child{
  border-top: 3px solid #003E8D;
  margin-top: 30px;
}
.header .nav button:hover > ul > li{
  display:block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.header .nav button > ul > li:last-child{
  border: inherit;
}
.skiptranslate {
  display: none;
}
.goog-te-gadget {
  display: block;
  height: auto;
  font-size: 0 !important;
  color: #000;
  border: inherit;
}
.goog-te-gadget select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: center;
  width: 70px;
  padding: 10px 0;
  border-radius: 50px;
  border-style: none;
  border: inherit;
  background-color: #ffffff2e;
  color: #fff;
}
.hd_lang span {
  display: none;
}
/* 푸터 */
.footer {
  display: flex;
  flex-wrap: wrap;
  padding: 3% 7%;
  background-color: #121417;
  color: #fff;
  justify-content: space-around;
}
.footer p {
  margin-bottom: 1%;
  font-weight: 600;
  font-size: 1.4rem;
}
.footer p:first-child {
  margin-bottom: 4%;
}
.footer p span {
  font-weight: 400;
}
.footer img {
  height: 8%;
  object-fit: contain;
  opacity: 0.6;
}

/****** 메인 *******/
.main .intro {
  display: grid;
  align-content: center;
  justify-content: left;
  color: #fff;
  width: 100%;
  height: 100vh;
  padding: 0 7%;
  background-image: linear-gradient(
      to right,
      rgba(16, 35, 67, 1) 0%,
      rgba(16, 35, 67, 0.5) 100%
    ),
    url("./image/cranes-3703469_1280\ 2.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  font-weight: bold;
}
.main .intro p {
  margin-top: 1%;
}
/* 생산 */
.our_product {
  display: flex;
  font-size: 2.5rem;
  font-weight: bold;
  min-height: 100vh;
  text-align: center;
  padding: 14% 7%;
  background-color: #f5f5f5;
  align-items: center;
  background-image: url("./image/HOME_BUSINESS\ AREA.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.our_product h3 {
  margin-bottom: 5%;
  text-align: left;
  color: #fff;
  margin-right: 150px;
}
.wrap_container .our_product h3 {
  text-align: center;
  margin: 0;
}
.our_product .img_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
.our_product .img_container .img {
  max-width: 382px;
  max-height: 100%;
  text-align: left;
}
.our_product .img_container .img1,
.our_product .img_container .img2,
.our_product .img_container .img3 {
  height: 286px;
}

.our_product .img_container .img img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.our_product .img_container h4 {
  color: #fff;
  margin: 5% 0 3% 0;
}
.our_product .img_container p {
  white-space: normal;
  font-weight: normal;
  margin-bottom: 3%;
  color: #808080;
}
/* 퍼포먼스 */
.performances {
  display: block;
  height: 100%;
  background-color: #121417;
  color: #fff;
  text-align: center;
  padding: 10% 7%;
}
.performances h3 {
  margin-bottom: 5%;
}

.performances h3:first-child {
  font-weight: 500;
}
.wrap_container .performances .img1 {
  margin-bottom: 55px;
  margin-top: 5%;
  width: max(45%, 450px);
  object-fit: none;
  object-position: 0% 0%;
  height: 420px;
}
.wrap_container .performances .img1.img1_long {
  height: 1103px;
}
.performances .img2 {
  width: 100px;
  padding: 10px;
  color: #4e5968;
  display: block;
  margin: 0 auto;
  object-position: inherit;
  border: 1px solid #4e5968;
}
.performances .img2:hover {
  background-color: #4e5968;
  color: #fff;
  width: 100px;
  padding: 10px;
  display: block;
  margin: 0 auto;
}
/* contact_us */
.contact_us {
  padding: 10% 7%;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  background-color: #f5f5f5;
}
.contact_us h3 {
  margin-bottom: 10%;
}
.contact_us p {
  display: grid;
  grid-template-columns: 0.5fr 3fr 10fr;
  margin-bottom: 3%;
  text-align-last: left;
}
.contact_us p span {
  margin-left: 13px;
  font-weight: 300;
}
.contact_us iframe {
  min-width: 450px;
}

/********** 본문 공동 선언 **********/
.wrap_container {
  background-color: #fff;
  z-index: 1;
}
.wrap_container .sub_nav {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  position: fixed;
  top: 52vh;
  margin-bottom: 245px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1;
  pointer-events: none; 
}
.wrap_container .sub_nav button {
  margin-right: 7%;
  pointer-events: auto; 
}
.wrap_container .sub_nav button li {
  font-weight: 400;
  color: #003e8d;
  margin-bottom: 20px;
  height: auto;
  pointer-events: auto; 
}
.wrap_container .sub_nav button li span {
  margin-left: 10px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 1px solid #003e8d;
  border-radius: 50%;
  pointer-events: auto; 
}
.wrap_container .sub_nav button.selected li span {
  background-color: #003e8d;
}
.wrap_container .sub_nav2 button li {
  font-weight: 400;
  color: #fff;
  margin-bottom: 20px;
  height: auto;
}
.wrap_container .sub_nav2 button li span {
  margin-left: 10px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  border-radius: 50%;
}
.wrap_container .sub_nav2 button.selected li span {
  background-color: #fff;
}
.wrap_container .dashboard {
  display: grid;
  grid-template-rows: 245px 0fr 0fr;
  place-content: center;
  justify-items: center;
  width: 100%;
  height: auto;
  text-align: center;
  padding: 0 13%;
}
.wrap_container .dashboard.tab {
  padding: 0;
}
.wrap_container .dashboard.performance.tab {
  place-content: flex-start;
  grid-template-columns: 1fr;
  height: auto;
}
.wrap_container .dashboard h3 {
  grid-row: 2/3;
  margin-bottom: 5%;
}
.wrap_container.about{
  height: 100% !important;
  min-height: 100vh;
}
.wrap_container .performances img:nth-of-type(1),
.wrap_container .overview img,
.wrap_container .performance img,
.wrap_container .organization img {
  width: max(70%, 400px);
  grid-row: 3/4;
}
.wrap_container .performances img:nth-of-type(1) {
  grid-row: 3/4;
  width: max(70%, 600px);
}
.wrap_container .performances .img2 {
  grid-row: 4/5;
  margin-bottom: 40px;
}
/* 상세선언 */
.wrap_container .intro {
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-content: center;
  width: 100%;
  height: 100vh;
  padding: 0 13%;
}
.wrap_container .intro img {
  max-width: 318px;
  object-fit: cover;
  margin: 1% 10%;
}
.wrap_container .intro div {
  width: 800px;
  text-align: left;
}
.wrap_container .intro div h3 {
  color: #003e8d;
  line-height: 1.5;
  margin-bottom: 8%;
  font-weight: 500;
}
.wrap_container .intro div p {
  line-height: 3.5rem;
  margin: 5% 0;
}
.wrap_container .intro div b{
  font-weight: 300 !important;
  font-size: 1.7rem;
}
.wrap_container .location {
  height: auto;
  align-items: center;
}
.wrap_container .location .box{
  width: 913.16px;
}
.wrap_container .location .contact_us p{
  font-size: 1.4rem;
}
.box {
  grid-row: 3/4;
  text-align: left;
}
.box_container {
  display: flex;
  flex-wrap: wrap;
  grid-row: 4/5;
  min-width: 390px;
  margin-bottom: 5%;
}
.box2 .box3 {
  text-align: left;
  margin-bottom: 200px;
}
.box2 > h4,
.box3 > h4 {
  text-align: center;
  color: #003e8d;
  margin-bottom: 5%;
}
.box2 {
  padding-right: 10px;
}
.box3 {
  padding-left: 10px;
}
.wrap_container .location .contact_us {
  background-color: #f5f5f5;
  border-radius: 10px;
  margin: 30px 0;
}
.box h4 {
  color: #003e8d;
}
.wrap_container .our_product {
  margin: 0;
  padding: 0;
  place-content: inherit;
  background-color: #fff;
}
.wrap_container .our_product .img_container {
  width: 100%;
  grid-row: 3/4;
  white-space: nowrap;
  justify-content: space-around;
  padding: 0 7%;
}
.wrap_container .our_product .img_container .img {
  height: auto;
}
.wrap_container .performance1 img {
  margin-bottom: 5%;
}
.wrap_container .performance h3 img {
  width: 10px ;
}
.wrap_container .performance h4 span {
  color: #003E8D;
}
.wrap_container .our_product .img_container .img1:hover,
.wrap_container .our_product .img_container .img2:hover,
.wrap_container .our_product .img_container .img3:hover {
  border: 3px solid #3751ff;
  border-radius: 13.5px;
}

.wrap_container .dashboard.tab {
  height: -webkit-fill-available;
}
.tab_menu {
  grid-row: 2/3;
  margin-bottom: 5%;
}
.tab_menu ul {
  width: 60vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-around;
  font-size: 2rem;
  display: table;
  border-collapse: collapse;
}
.tab_menu ul li {
  display: table-cell;
  border: 1px solid #000;
  padding: 10px;
  cursor: pointer;
}
.tab_menu .selected {
  background-color: #003e8d;
  color: #fff;
}
.wrap_container .container {
  grid-row: 2/3;
  width: 100%;
  text-align: left;
}
.wrap_container .img_container {
  grid-row: 3/4;
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.wrap_container .container .img_container {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  grid-row: inherit;
  flex-wrap: wrap;
}
.wrap_container .container .img_container .img {
  width: 372px;
}
.wrap_container .tab .img_container .img img {
  width: 100%;
  height: 248px;
  object-fit: none;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 14px 0px rgb(0 0 0 / 25%);
}
.wrap_container .tab .carlift .section1 .img_container .img img {
  object-fit: cover;
}
.wrap_container .container .img_container p {
  white-space: normal;
  font-size: 1.4rem;
  margin-top: 5px;
}
/* our_product */
.container > div > div {
  padding: 0 13%;
}
.container p {
  margin: 15px 0 5% 0;
}
.container .img_container .img{
  margin-bottom: 100px;
}
.container .img h4 {
  margin-top: 20px;
}
.section1 h4:nth-of-type(1) {
  color: #003e8d;
}
.section2,
.section4 {
  background-color: #f5f5f5;
}
.section2 h4,
.section3 h4,
.section4 h4 {
  padding-top: 100px;
}
.section2 .img h4 {
  padding: 0;
  color: #003e8d;
}
.section3 p {
  margin-top: 40px;
  line-height: 3rem;
  margin-bottom: 100px;
}
.wrap_container .container .section4 .img_container .img,
.wrap_container .technologicalCapability .img_container .img,
.wrap_container .tab .other_crane .section1 .img_container .img {
  text-align: center;
  width: 170px;
  height: auto;
}
.wrap_container .container .section4 .img_container .img img,
.wrap_container .technologicalCapability .img_container .img,
.wrap_container .tab .other_crane .section1 .img_container .img img {
  border-radius: 10px;
  object-fit: contain;
  height: inherit;
}
.wrap_container .container .section4 .img_container .img:nth-of-type(8) img {
  object-fit: none;
}
.wrap_container .tab .bucket_hoist .section1 .img_container .img img {
  height: 248px;
  object-fit: cover;
  border-radius: 10px;
}
.wrap_container .tab .other_crane .section1 .img_container {
  justify-content: space-between;
}
.wrap_container .tab .other_crane .section1 .img_container .img img {
  object-fit: none;
}

.wrap_container .dashboard.technologicalCapability {
  display: block;
  padding: 0;
  margin-top: 245px;
}

.wrap_container .dashboard.technologicalCapability div {
  padding: 0 13%;
  grid-row: 3/4;
  margin-bottom: 5%;
}
.wrap_container .dashboard.technologicalCapability div img {
  margin-top: 7%;
}
.wrap_container
  .dashboard.technologicalCapability.technologicalCapability
  .img2 {
  width: 100%;
  padding: 0 13%;
  background-color: #f5f5f5;
}
.wrap_container
  .dashboard.technologicalCapability.technologicalCapability
  .img3 {
  padding: 245px 17%;
  width: 100%;
}
.wrap_container .technologicalCapability2 .img_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
}
.wrap_container .dashboard.technologicalCapability .img_container .img {
  padding: 0;
  text-align: center;
}
.wrap_container .dashboard.technologicalCapability .img_container .img p{
  font-size: 1.3rem;
  line-height: 1.6rem;
}
.wrap_container .dashboard.technologicalCapability .img_container .img img {
  width: 100%;
  object-fit: contain;
  margin-bottom: 15px;
  cursor: pointer;
}
.popup {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: -50%;
  left: -50%;
  transform: translate(50%, 50%);
  background-color: rgba(0, 0, 0, 0.63);
  display: grid;
  place-items: center;
  z-index: 20;
  overflow: auto;
}
.popup img {
  padding: 3% 10%;
  min-width: 50%;
}

/* 반응형 */

@media screen and (min-width: 300px) and (max-width: 1200px) {
  .header {
    padding: 2% 2%;
    z-index: 20;
  }
  .header .nav button {
    width: 200px;
    font-size: 2vw;
  }
  .header .nav button > ul{
    max-width: 20%;
  }
  .wrap_container .sub_nav {
    display: none;
    /* top: 120px; */
    /* position: relative; */
  }
  .contact_us {
    justify-content: inherit;
  }
  .contact_us iframe {
    min-width: 300px;
  }
  .footer {
    flex-wrap: inherit;
  }
  .footer img {
    display: none;
  }
  .footer p {
    font-size: 2vw;
  }
  .box iframe,  .box2 iframe,  .box3 iframe {
    width: 100%;
  }
  .box2 {
    padding: 0;
  }
  .box h4 {
    margin-bottom: 5%;
  }
  .box_container {
    justify-content: center;
    flex-wrap: wrap;
    min-width: 300px;
  }
  .wrap_container .location .box{
    width: 100%;
  }
  .wrap_container .our_product,
  .wrap_container .dashboard.tab {
    /* grid-template-rows: 200px 0fr 3fr; */
    height: 100%;
  }

  .wrap_container .our_product .img_container {
    white-space: inherit;
  }

  .wrap_container .performance .img_container {
    padding-bottom: 5%;
  }

  .wrap_container .sub_nav li {
    margin-left: 10px;
    font-size: 2vw;
  }
  .wrap_container.about .performances {
    background-color: #121417;
  }
  .wrap_container .performances .img2 {
    display: none;
  }
  .wrap_container .location .contact_us {
    display: block;
  }
  .contact_us p{
    grid-template-columns: 0.5fr 4fr 9fr;
  }
  .contact_us p span{
    display: block;
    width: 100%;
  }
  .wrap_container .intro {
    display: block;
    margin-top: 150px;
    height: 100%;
  }
  .wrap_container .intro div {
    width: 100%;
  }
  .wrap_container .intro div h3,
  .wrap_container .dashboard h3 {
    font-size: 5vw;
  }
  .wrap_container .intro div h4 {
    font-size: 4.8vw;
  }
  .wrap_container .intro div p {
    font-size: 4vw;
    white-space: normal;
    line-height: 1.5;
  }
  .wrap_container .intro .word_wrap{
    padding-bottom: 50px;
  }
  .wrap_container .intro img {
    width: 150px;
    margin-top: 5%;
  }
  .wrap_container .dashboard {
    grid-template-rows: 200px 0fr 0fr;
  }
  .wrap_container.about{
    height: 100%;
  }
  .wrap_container .our_product {
    grid-template-rows: 120px 0fr 0fr;
  }
  .wrap_container .dashboard.technologicalCapability {
    margin-top: 180px;
  }
  .wrap_container
    .dashboard.technologicalCapability.technologicalCapability
    .img3 {
    padding: 10%;
  }
  .wrap_container .dashboard.technologicalCapability div {
    margin: 10px auto;
  }
  .wrap_container .performances img:nth-of-type(1),
  .wrap_container .overview img,
  .wrap_container .performance img,
  .wrap_container .organization img {
    width: 90%;
    object-fit: contain;
    object-position: 50%;
  }
  .our_product {
    display: block;
  }
  .our_product .img_container .img {
    max-width: 100%;
  }
  .our_product .img_container .img1,
  .our_product .img_container .img2,
  .our_product .img_container .img3 {
    height: auto;
  }
  .container p {
    margin-bottom: 5%;
  }
  .section2 h4,
  .section3 h4,
  .section4 h4 {
    padding-top: 5%;
  }
  .wrap_container .container .img_container {
    margin-top: 5%;
  }
  .wrap_container .container .img_container .img{
    margin-bottom: 5%;
  }
  .section3 p{
    margin-top: 5%;
    line-height: 1.5;
  }
  .wrap_container .performance h3 img {
    width: 10px ;
  }
  .wrap_container .performance h3 span {
    font-size: 3vw;
  }
}
